<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Start Terms & Conditions Area -->
<section class="terms-conditions-area ptb-100">
    <div class="container">

        <div class="terms-conditions">
            <div class="title">
                <span>Home&nbsp;&nbsp;/&nbsp;&nbsp;</span><span style="color: #003333;">Impressum</span>
                <h2>Impressum</h2>
            </div>


            <div class="conditions-content">
                <h3>Inhaber</h3>
                <p>Tarawat Karimi-Azizi Frauenärztin für Gynäkologie und Geburtshilfe Frankfurter Str. 100a 53773 Hennef</p>
            </div>

            <div class="conditions-content">
                <h3>Kontaktmöglichkeiten</h3>
                <p>E-Mail-Adresse: info@frauenärztin-hennef.de</p>
                <p>Telefon: 0224283025 </p>
                <p>Fax: 0224283261</p>
            </div>

            <div class="conditions-content">
                <h3>Journalistisch-redaktionelle Angebote</h3>
                <p>Inhaltlich verantwortlich: Adresse wie oben</p>
                <p>Aufsichtsbehörde:</p>
                <p>Ministerium der Finanzen des Landes Nordrhein-Westfalen<br/>
                    Jägerhofstraße 6<br/>
                    40479 Düsseldorf</p>

                <p>Telefon: 0211 4972-0<br/>
                    Website: https://www.nordrheinische-aerzteversorgung.de/impressum/aufsichtsbehoerde</p>
            </div>

            <div class="conditions-content">
                <h3>Berufsbezeichnung</h3>
                <p>Gesetzliche Berufsbezeichnung: Arzt/ Ärztin.<br/>
                    Land in dem die Berufsbezeichnung verliehen wurde: Deutschland.
                </p>
            </div>

            <div class="conditions-content">
                <h3>Kammer</h3>
                <p>Kammer (oder vergleichbare berufsrechtlicher Organisation):</p>
                <p>Zuständige Ärztekammer:<br/>
                    Landesärztekammer Nordrhein<br/>
                    Tersteegenstraße 9<br/>
                    40474 Düsseldorf
                    </p>
                <p>Website und Kontakt der Kammer: https://www.aekno.de/</p>
            </div>

            <div class="conditions-content">
                <h3>Berufsrechtliche Regelungen</h3>
                <p>Berufsrechtliche Regelungen:</p>
                <p>Berufsordnung der Landesärztekammer Nordrhein</p>
                <p>Die aktuelle Berufsordnung ist auf der Webseite der Ärztekammer Nordrhein unter folgendem Link einsehbar: https://www.aekno.de/aerzte/berufsordnung</p>
            </div>

            <div class="conditions-content">
                <h3>Social Media und andere Onlinepräsenzen</h3>
                <p>Dieses Impressum gilt auch für die folgenden Social-Media-Präsenzen und Onlineprofile:<br/>
                    https://instagram.com/praxiskarimiazizi
                </p>
            </div>

            <div class="conditions-content">
                <h3>Haftungs- und Schutzrechtshinweise</h3>
                <p>Haftungsausschluss: Die Inhalte dieses Onlineangebotes wurden sorgfältig und nach unserem aktuellen Kenntnisstand erstellt, dienen jedoch nur der Information und entfalten keine rechtlich bindende Wirkung, sofern es sich nicht um gesetzlich verpflichtende Informationen (z. B. das Impressum, die Datenschutzerklärung, AGB oder verpflichtende Belehrungen von Verbrauchern) handelt. Wir behalten uns vor, die Inhalte vollständig oder teilweise zu ändern oder zu löschen, soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind freibleibend und unverbindlich.</p>
                <p>Links auf fremde Webseiten: Die Inhalte fremder Webseiten, auf die wir direkt oder indirekt verweisen, liegen außerhalb unseres Verantwortungsbereiches und wir machen sie uns nicht zu Eigen. Für alle Inhalte und Nachteile, die aus der Nutzung der in den verlinkten Webseiten aufrufbaren Informationen entstehen, übernehmen wir keine Verantwortung.</p>
                <p>Urheberrechte und Markenrechte: Alle auf dieser Website dargestellten Inhalte, wie Texte, Fotografien, Grafiken, Marken und Warenzeichen sind durch die jeweiligen Schutzrechte (Urheberrechte, Markenrechte) geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren Rechten oder den Rechten der jeweiligen Urheber bzw. Rechteinhaber.</p>
                <p>Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</p>
            </div>



        </div>
    </div>
</section>
<!-- End Terms & Conditions Area -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
