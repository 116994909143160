<!-- Start Coming Soon Area -->
<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="coming-soon-content">
                <div class="col-lg-12">
                    <a routerLink="/" class="logo"><img src="assets/img/logo-02.png" width="100%" alt="image"></a>
                </div>

                <h2>Tarawat Karimi-Azizi</h2>
                <h3>Praxis für Gynäkologie und Geburtshilfe</h3>
                <p>Unser Internetauftritt befindet sich derzeit noch im Aufbau. Bei Fragen können Sie uns gerne persönlich ansprechen.</p>

                <!--
                <br/>
                <h3>Liebe Patientinnen,</h3>
                <p>aufgrund einer Renovierung bleibt unsere Praxis <strong>vom 17.07. - 08.08.2023 geschlossen</strong>.</p>
                <p>Die <strong>Vertretung</strong> übernimmt <strong>vom 17.07. - 21.07.2023 die Praxis Frau Dr. Ghafoorie</strong><br/>
                Frankfurter Str. 56<br/>
                53773 Hennef</p>

                <p><strong>Vom 24.07. - 08.08.2023</strong> macht die <strong>Praxis Dr. Olmos Vertretung</strong>
                <br/>
                Humperdinckstr. 26<br/>
                53773 Hennef</p>

                <p><strong>Ab dem 09.08.2023 sind wir wieder wie gewohnt für Sie da.</strong></p>
                -->

                <!-- <div id="timer" class="flex-wrap d-flex justify-content-center">
                    <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}} <span>Days</span></div>
                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}} <span>Hours</span></div>
                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}} <span>Minutes</span></div>
                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}} <span>Seconds</span></div>
                </div>
                <form class="newsletter-form">
                    <div class="form-group">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <span class="label-title"><i class='bx bx-envelope'></i></span>
                    </div>
                    <button type="submit" class="default-btn">Subscribe</button>
                    <div id="validator-newsletter" class="form-result"></div>
                    <p>If you would like to be notified when your app is live, Please subscribe to our mailing list.</p>
                </form> -->

                <!-- Start Contact Area -->
                <section class="contact-area ptb-100">
                    <div class="container">
                        <div class="row" style="text-align: left;">

                            <div class="col-lg-12">
                                <div class="address-and-week">
                                    <p>Sprechzeiten</p>

                                    <div class="address-week">
                                        <ul class="address">
                                            <li>
                                                <i class="flaticon-telephone-1"></i>
                                                <span>Telefon:</span>
                                                <a href="tel:+49224283025">&nbsp;+49 (02242) 830-25</a>
                                            </li>
                                            <li>
                                                <i class="flaticon-arroba"></i>
                                                <span>Email:</span>
                                                <a href="mailto:info@frauenärztin-hennef.de">&nbsp;info@frauenärztin-hennef.de</a>
                                            </li>
                                            <li>
                                                <i class="flaticon-address-1"></i>
                                                <span>Address:</span>
                                                Frankfurter Straße 100a<br/>
                                                53773 Hennef
                                            </li>
                                        </ul>

                                        <ul class="week">
                                            <li>
                                                Montag:
                                                <span>8:00 – 12:00 &nbsp;|&nbsp; 13:30 – 16:30</span>
                                            </li>
                                            <li>
                                                Dienstag:
                                                <span>8:00 – 12:00 &nbsp;|&nbsp; 13:30 – 16:30</span>
                                            </li>
                                            <li>
                                                Mittwoch:
                                                <span>8:00 – 14:00</span>
                                            </li>
                                            <li>
                                                Donnerstag:
                                                <span>8:00 – 12:00 &nbsp;|&nbsp; 13:30 – 16:30</span>
                                            </li>
                                            <li>
                                                Freitag:
                                                <span>8:00 – 12:00</span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- End Contact Area -->

            </div>
        </div>
    </div>

    <div class="online-treatment-shape-1"><img src="assets/img/online-treatment-shape-1.png" alt="Image"></div>
    <div class="online-treatment-shape-2"><img src="assets/img/online-treatment-shape-2.png" alt="Image"></div>
</div>
