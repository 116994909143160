<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Kontakt</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Kontakt</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <!-- <div class="contact-form">
                    <div class="contact-title">
                        <h2>Drop us message for any query</h2>
                    </div>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" name="name" id="name" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="email" name="email" id="email" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Subject</label>
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Phone</label>
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Message</label>
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="10"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn btn-two">
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </form>
                </div> -->
                <!-- Start Map Area -->
                <div class="contact-form map-area ">
                    <img src="assets/img/contact/maps.png">
                    <a href="https://www.google.com/maps/dir//Frankfurter+Str.+100A+53773+Hennef+(Sieg)+Deutschland/@50.7749938,7.2842639,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x47bee80520d8ddc3:0x17c1ece5304cfa93" class="default-btn" target="_blank" >Mit Google Maps öffnen*</a>
                    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2523.0310937616678!2d7.2820752161866755!3d50.77499717180641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bee80520d8ddc3%3A0x17c1ece5304cfa93!2sFrankfurter%20Str.%20100A%2C%2053773%20Hennef%20(Sieg)%2C%20Deutschland!5e0!3m2!1sde!2sae!4v1680259467025!5m2!1sde!2sae"></iframe> -->
                </div>
                <!-- End Map Area -->
            </div>

            <div class="col-lg-4">
                <div class="address-and-week">
                    <!-- <p>For 24/7 emergency please call us. If is no emergency, contact at normal time.</p> -->

                    <div class="address-week">
                        <ul class="address">
                            <li>
                                <i class="flaticon-telephone-1"></i>
                                <span>Telefon:</span>
                                <a href="tel:+49224283025">+49 (02242) 830-25</a>
                            </li>
                            <li>
                                <i class="flaticon-arroba"></i>
                                <span>Email:</span>
                                <a href="mailto:info@xn--frauenrztin-hennef-qtb.de">info@frauenärztin-hennef.de</a>
                            </li>
                            <li>
                                <i class="flaticon-address-1"></i>
                                <span>Address:</span>
                                Frankfurter Straße 100 a, <br/>    53773 Hennef
                            </li>
                        </ul>



                        <ul class="week opentimes">
                            <li>
                                Montag:
                                <span>8:00 – 12:00</span>
                                <div><span class="right">13:30 – 16:30</span></div>
                            </li>
                            <li>
                                Dienstag:
                                <span>8:00 – 12:00</span>
                                <div><span class="right">13:30 – 16:30</span></div>
                            </li>
                            <li>
                                Mittwoch:
                                <span>8:00 – 14:00</span>
                            </li>
                            <li>
                                Donnerstag:
                                <span>10:00 – 14:00</span>
                                <div><span class="right">15:00 – 18:30</span></div>
                            </li>
                            <li>
                                Freitag:
                                <span>8:00 – 12:00</span>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->
<!-- <div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2523.0310937616678!2d7.2820752161866755!3d50.77499717180641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bee80520d8ddc3%3A0x17c1ece5304cfa93!2sFrankfurter%20Str.%20100A%2C%2053773%20Hennef%20(Sieg)%2C%20Deutschland!5e0!3m2!1sde!2sae!4v1680259467025!5m2!1sde!2sae"></iframe>
</div> -->
<!-- End Map Area -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
