<!-- Footer -->
<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="Image"></a>
                    <p>Our priority is to ensure the well being of patients. Our service and the work efficiency of our staff are the reason for our success to ensure the well being.</p>
                    <p class="address"><span>Address:-</span> 6890 Blvd, The Bronx, NY 1058 New York, USA</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Our departments</h3>

                    <ul>
                        <li><a routerLink="/">Pediatric clinic</a></li>
                        <li><a routerLink="/">Oral medicine</a></li>
                        <li><a routerLink="/">Neurology</a></li>
                        <li><a routerLink="/">Disability</a></li>
                        <li><a routerLink="/">Cardiology</a></li>
                        <li><a routerLink="/">Pediatrician</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Popular links</h3>

                    <ul>
                        <li><a routerLink="/">About us</a></li>
                        <li><a routerLink="/">Emergency department</a></li>
                        <li><a routerLink="/">Contact us</a></li>
                        <li><a routerLink="/">Terms and conditions</a></li>
                        <li><a routerLink="/">Privacy policy</a></li>
                        <li><a routerLink="/">Find a doctor</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact us</h3>
                    <p>For 24/7 emergency please call us. If there is no emergency, contact at time.</p>
                    <ul>
                        <li>
                            <span>Call:-</span>
                            <a href="tel:+15143125678">+1 (514) 312-5678</a>
                        </li>
                        <li>
                            <span>Email:-</span>
                            <a href="mailto:support@vuci.com">support@vuci.com</a>
                        </li>
                    </ul>
                    <ul class="open-day">
                        <li>
                            Weekdays
                            <span>8:00 am - 6:00 pm</span>
                        </li>
                        <li>
                            Sunday
                            <span>9:00 am - 3:00 pm</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Footer -->

<!-- Copyright -->
<div class="copy-right-area">
    <div class="container">
        <p>© Vuci is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
    </div>
</div>
<!-- End Copyright -->

<!-- Go Top -->
<div class="go-top">
    <i class="bx bx-chevrons-up"></i>
    <i class="bx bx-chevrons-up"></i>
</div>
<!-- End Go Top -->