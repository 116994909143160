<!-- Navbar -->
<!-- <app-navbar-style-one></app-navbar-style-one> -->
<app-navbar-style-two></app-navbar-style-two>
<!-- <app-navbar-style-three></app-navbar-style-three> -->
<!-- End Navbar -->

<!-- Banner -->
<section class="banner-area">
    <!-- The video -->
    <video autoplay muted loop id="myVideo" oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="assets/video/video2.mp4" type="video/mp4">
    </video>

    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content videotext overlay-content">
                    <h1 class="wow fadeInDown" data-wow-delay="1s">Herzich Willkommen</h1>
                    <h2 class="wow fadeInDown" >in der Praxis für Gynäkologie und Geburtshilfe</h2>
                    <p class="wow fadeInDown" data-wow-delay="1s">Mein Team und ich, Tarawat Karimi Azizi begrüßen Sie herzlich und freuen uns auf Ihren Besuch!</p>

                </div>

            </div>
        </div>
    </div>
</section>
<!-- End Banner -->


<!-- Feature -->
<!-- <div class="feature-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-feature">
                    <i class="flaticon-doctor-1"></i>
                    <span>Find a doctor</span>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-feature active">
                    <img src="assets/img/icon/appointment.png" alt="Image">
                    <span>Request an appointment</span>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-feature">
                    <i class="flaticon-first-aid-kit"></i>
                    <span>Emergency case</span>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Feature -->

<!-- Start Choose Us -->
<section class="choose-us-area ptb-100">

    <div class="container">
        <div class="section-title">
            <!-- <span>Über uns</span> -->
            <h2>Über uns</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-us-img">
                    <img src="assets/img/doctors/doctors-team.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-us-content">
                    <h3>Mein Team und ich, Tarawat Karimi Azizi begrüßen Sie herzlich
                        und freuen uns auf Ihren Besuch!</h3>
                    <p>Liebe Patientinnen,
                        Zum 01.04.2023 habe ich die gynäkologische Praxis in Hennef von Dr. Fischer übernommen. Ich freue mich Sie in vertrauensvoller  und entspannter Atmosphäre kennenzulernen und hoffe Sie zukünftig als Ihre Gynäkologin begleiten zu dürfen.
                        Ihre Gesundheit und Ihre Bedürfnisse stehen bei uns im Mittelpunkt.
                    </p>
                    <a routerLink="/team" class="default-btn">Team</a>
                </div>
            </div>
        </div>
    </div>

    <!--<div class="choose-us-shape"><img src="assets/img/choose-us-shape.png" alt="Image"></div>-->
</section>
<!-- End Choose Us -->



<!-- Services -->
<!--
<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="section-title text-align-left">
                    <h3>Das bin ich...</h3>
                    <p>Als gebürtige Afghanin absolvierte ich mein Studium für Humanmedizin von 2004 bis 2010 an der Heinrich- Heine- Universität in Düsseldorf.
                        Für meine Facharztweiterbildung war ich 4 Jahre im Kreiskrankenhaus Mechernich und für ein Jahr im Krankenhaus der Augustinerinnen in Köln tätig, so dass ich 2016 erfolgreich meine Facharztanerkennung in der Gynäkologie und Geburtshilfe  erlangen konnte.</p>
                    <p>Nach meiner Facharztanerkennung kehrte ich als Oberärztin zurück nach Mechernich und war bis zur Praxisübernahme im Kreiskrankenhaus Mechernich tätig. Während dieser Zeit konnte ich sowohl im Kreissaal als auch im Bereich der operativen Gynäkologie Erfahrung sammeln. </p>
                    <p>Mit meinen zwei Töchtern und meinem Ehemann lebe ich in Bornheim. </p>
                    <p>Ich freue mich auf den neuen Abschnitt in der Niederlassung und möchte Sie in allen Lebensabschnitten begleiten und unterstützen, unabhängig von Alter, Versicherung, Sozialstatus, Herkunft, sexueller Orientierung etc. </p>
                    <p>Für die Frauengesundheit ist eine ganzheitliche Betrachtung von Bedeutung. Daher möchte ich, dass Sie sich über die kompetente fachärztliche Versorgung hinaus rundum wohlfühlen.
                        Mein höchstes Ziel ist Ihre Zufriedenheit.</p>
                    <p>Ich freue mich Sie kennenzulernen!</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 mt">
                <div class="choose-us-img ">
                    <img src="/assets/img/doctor-details/doctor-1.png" alt="Image">
                </div>
            </div>
        </div>


    </div>

    <div class="services-shape"><img src="assets/img/services-shape.png" alt="Image"></div>
</section>
-->
<!-- End Services -->

<!-- Testimonial -->
<!-- <section class="testimonial-area pb-100">
    <div class="container">
        <div class="section-title text-align-left">
            <span>Testimonial</span>
            <h2>What do our patients say?</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-1.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Markus Twain</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-2.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Anna Deo</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-3.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Kilvis Smith</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Testimonial -->

<!-- Doctors -->
<!-- <section class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-align-left">
            <span>Our specialists</span>
            <h2>We have specialist doctor’s for emergency services</h2>
        </div>

        <div class="doctors-slider owl-theme owl-carousel">
            <div class="single-doctors one">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-1.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Cosmetic Surgeon</span>
                    <h3>Bernarda N. Bacon</h3>
                </div>
            </div>

            <div class="single-doctors two">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-2.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Surgery Surgeon</span>
                    <h3>Brian Christensen</h3>
                </div>
            </div>

            <div class="single-doctors three">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-3.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Dental surgeon</span>
                    <h3>Aimee C. Mayfield</h3>
                </div>
            </div>

            <div class="single-doctors four">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-4.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Cardiothoracic Surgeon</span>
                    <h3>Gabriel Henderson</h3>
                </div>
            </div>

            <div class="single-doctors five">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-5.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Surgeon</span>
                    <h3>Kilva Henderson</h3>
                </div>
            </div>

            <div class="single-doctors six">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-6.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Cardiology</span>
                    <h3>Ana Henderson</h3>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Doctors -->

<!-- Portfolio -->
<!-- <section class="our-portfolio-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="section-title text-align-left">
                    <span>Our portfolio</span>
                    <h2>Below are some examples of all the portfolios we provide to our patients</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="section-btn">
                    <a routerLink="/portfolio-column-three" class="default-btn">View all portfolios</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-portfolio">
                    <img src="assets/img/portfolio/portfolio-1.jpg" alt="Image">
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Conditions we treat</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-portfolio">
                    <img src="assets/img/portfolio/portfolio-2.jpg" alt="Image">
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Research and innovation</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-portfolio">
                    <img src="assets/img/portfolio/portfolio-3.jpg" alt="Image">
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Doctor examining physiotherapy </a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Portfolio -->

<!-- Start Partner -->
<!-- <div class="partner-area tb-100">
    <div class="container">
        <div class="partner-slider partner-bg owl-theme owl-carousel">
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-1.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-2.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-3.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-4.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-5.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-3.png" alt="Image"></a>
            </div>
        </div>
    </div>

    <div class="partner-shape"><img src="assets/img/partner/partner-shape.png" alt="Image"></div>
</div> -->
<!-- End Partner -->

<!-- Blog -->
<!-- <section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="section-title text-align-left">
                    <span>Recent news</span>
                    <h2>All the recent news about our treatment has been published</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="section-btn">
                    <a routerLink="/blog-column-one" class="default-btn">View all news</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-1.jpg" alt="Image"></a>
                        <div class="date">
                            <ul>
                                <li>07</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">It might be time to seek a medical your muscle and joint pain</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">Curtis Warren</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-2.jpg" alt="Image"></a>
                        <div class="date">
                            <ul>
                                <li>08</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">The reassurance of a safe pregnancy through the birth of your baby</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">James Lopez</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-3.jpg" alt="Image"></a>
                        <div class="date">
                            <ul>
                                <li>09</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">Read up on different types of fevers that may require a doctor</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">Milton Baines</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Blog -->

<!-- Subscribe -->
<!-- <section class="subscribe-area pb-100">
    <div class="container">
        <div class="subscribe-bg">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="subscribe-content">
                        <img src="assets/img/subscribe-img.png" alt="Image">
                        <h3>Sing up for newsletter</h3>
                        <span>Subscribe to the newsletter for all the latest updates</span>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter email address" name="email">
                        <button class="default-btn" type="submit">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Subscribe -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
