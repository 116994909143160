<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Unsere Praxisräume</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Unsere Praxisräume</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Our Portfolio Area -->
<section class="our-portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Unsere Praxisräume</span>
            <h2>Hier können Sie erste Einblicke erhalten</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-sm-6">
                <div class="single-portfolio">
                    <img src="assets/img/praxis/praxis1.jpg" alt="Image">
                    <div class="portfolio-content">
                        <h3><a routerLink="/contact">Rufen Sie uns an!</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-portfolio">
                    <img src="assets/img/praxis/praxis4.jpg" alt="Image">
                    <div class="portfolio-content">
                        <h3><a routerLink="/leistungsspektrum">Unsere Leistungen im Überblick</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-portfolio">
                    <img src="assets/img/praxis/praxis2.jpg" alt="Image">

                    <div class="portfolio-content">
                        <h3>
                            <a routerLink="/contact">
                                Unsere Öffnungszeiten
                            </a>
                        </h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-portfolio">
                    <img src="assets/img/praxis/praxis3.jpg" alt="Image">

                    <div class="portfolio-content">
                        <h3>
                            <a routerLink="/contact">
                                Standort erkundigen
                            </a>
                        </h3>
                    </div>
                </div>
            </div>


<!--
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/portfolio-two-column" class="prev page-numbers">
                        <i class="bx bx-chevron-left"></i>
                    </a>

                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/portfolio-two-column" class="page-numbers">2</a>
                    <a routerLink="/portfolio-two-column" class="page-numbers">3</a>

                    <a routerLink="/portfolio-two-column" class="next page-numbers">
                        <i class="bx bx-chevron-right"></i>
                    </a>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Our Portfolio Area -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
