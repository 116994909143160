import { Component } from '@angular/core';

@Component({
  selector: 'app-portfolio-details2',
  templateUrl: './portfolio-details2.component.html',
  styleUrls: ['./portfolio-details2.component.scss']
})
export class PortfolioDetails2Component {

}
