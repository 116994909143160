<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Jobs</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Jobs</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Portfolio Details Area -->
<div class="portfolio-details-area ptb-100">
    <div class="container">
        <div class="portfolio-details-content">
            <!-- <div class="portfolio-details-img">
                <img src="assets/img/portfolio-details/portfolio-details.jpg" alt="Image">
            </div> -->

            <div class="portfolio-top-content">
                <div class="news-content">
                    <h3>Wir suchen dich!</h3>

                    <p>Unsere moderne gynäkologische Praxis im Zentrum von Hennef sucht ab sofort eine engagierte <strong>MFA</strong>.</p>
                    <div class="news-content-2">
                        <strong>Voraussetzungen sind:</strong>
                        <ul>
                            <li>- eine abgeschlossene Ausbildung,</li>
                            <li>- gute Deutschkenntnisse</li>
                            <li>- und technisches Geschick.</li>
                        </ul>
                    </div>
                    <div class="news-content-2">
                        <strong>Wir bieten:</strong>
                            <ul>
                                <li>- flexible Arbeitszeiten (keine Wochenendarbeit),</li>
                                <li>- übertarifliche Bezahlung,</li>
                                <li>- 13. Monatsgehalt,</li>
                                <li>- Provision und ein Deutschlandticket.</li>
                            </ul>
                        <p>Bewerben Sie sich und werden Sie Teil unseres freundlichen Teams!</p>
                    </div>

                    <p>Für Fragen stehen wir Ihnen natürlich zur Verfügung.</p>

                    <p><i class="flaticon-arroba">&nbsp;</i><span>Email: &nbsp;</span><a href="mailto:info@xn--frauenrztin-hennef-qtb.de">info@frauenärztin-hennef.de</a></p>
                    <p><a routerLink="/contact" class="default-btn" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kontakt</a></p>
                </div>




            </div>
        </div>
    </div>
</div>
<!-- End Portfolio Details Area -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
