<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->


<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Team</h2>
            <ul>
                <li><a routerLink="/">Home</a> / <span class="active">Mein Team</span></li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->
 <!-- Banner -->
<!--
<div class="page-title-area">

    <div class="container">
        <div class="page-title-content">
            <h2>Team</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Mein Team</li>
            </ul>
        </div>
    </div>
    <div class="container banner">
        <img src="assets/img/doctors/doctors-0.png" alt="Image">
    </div>
</div>
-->
<!-- Banner -->

<!--
<section class="banner-area">
    <img src="assets/img/doctors/doctors-0.png" alt="Image">

    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content contenttext overlay-content">
                    <h1 class="wow fadeInDown" data-wow-delay="1s">Team</h1>
                    <ul>
                        <li><a routerLink="/">Home</a> / <span class="active">Mein Team</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
-->
<!-- End Banner -->


<!-- Doctors -->
<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <!-- <div class="section-title">
            <h2>Unser Team</h2>
        </div> -->

        <!-- <div class="row">
            <div class="col-lg-3 col-sm-6">
                <a routerLink="/TarawatKarimi">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-0.png" alt="Image">
                    </div>
                    <div class="doctors-content">
                        <span>Gynäkologin</span>
                        <h3>Tarawat Karimi Azizi</h3>
                    </div>
                </div>
                </a>
            </div>
        </div> -->

        <div class="row">

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-2.png" alt="Image">
                    </div>
                    <div class="doctors-content">
                        <!-- <span> </span> -->
                        <h3>Vanessa Zygar</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-3.png" alt="Image">
                    </div>
                    <div class="doctors-content">
                        <!-- <span> </span> -->
                        <h3>Anna Hopper</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-4.png" alt="Image">
                    </div>
                    <div class="doctors-content">
                        <!-- <span> </span> -->
                        <h3>Rona Karimi</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-5.jpg" alt="Image">
                    </div>
                    <div class="doctors-content">
                        <!-- <span> </span> -->
                        <h3>Christina Balles</h3>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Doctors -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
