<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Persönliches</h2>
            <ul>
                <li><a routerLink="/">Home</a> / <span class="active">Persönliches</span></li>
                <!-- <li class="active">Persönliches</li> -->
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Doctor Details -->
<section class="doctors-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="doctors-sidebar">
                    <div class="doctors-img">
                        <img src="assets/img/doctor-details/doctor-1.png" alt="Image">
                        <ul>
                            <li><a href="https://www.facebook.com/profile.php?id=61557319630061"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/praxiskarimiazizi?igsh=Mjg4dHhteTRwbWkz"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="availability">
                        <h3><i class="bx bx-time"></i> Sprechstunden</h3>

                        <ul>
                            <li>
                                Montag
                                <span>8.00 - 12.00</span>
                            </li>
                            <li> &nbsp;
                                <span>13:30 - 16:30</span>
                            </li>
                            <li>
                                Dienstag
                                <span>8.00 - 12.00</span>
                            </li>
                            <li> &nbsp;
                                <span>13:30 - 16:30</span>
                            </li>
                            <li>
                                Mitwoch
                                <span>8.00 - 14.00</span>
                            </li>
                            <li>
                                Donnerstag
                                <span>10.00 - 14.00</span>
                            </li>
                            <li> &nbsp;
                                <span>15:00 - 18:30</span>
                            </li>
                            <li>
                                Freitag
                                <span>8.00 - 12.00</span>
                            </li>
                            <li>
                                Samstag & Sonntag
                                <span>Geschlossen</span>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="doctors-details mb-30">
                    <div class="doctors-history">

                        <h3>Tarawat Karimi Azizi</h3>
                        <span>Das bin ich...</span>
                        <p>Als gebürtige Afghanin absolvierte ich mein Studium für Humanmedizin von 2004 bis 2010 an der Heinrich- Heine- Universität in Düsseldorf. Für meine Facharztweiterbildung war ich 4 Jahre im Kreiskrankenhaus Mechernich und für ein Jahr im Krankenhaus der Augustinerinnen in Köln tätig, so dass ich 2016 erfolgreich meine Facharztanerkennung in der Gynäkologie und Geburtshilfe erlangen konnte.</p>

                        <p>Nach meiner Facharztanerkennung kehrte ich als Oberärztin zurück nach Mechernich und war bis zur Praxisübernahme im Kreiskrankenhaus Mechernich tätig. Während dieser Zeit konnte ich sowohl im Kreissaal als auch im Bereich der operativen Gynäkologie Erfahrung sammeln.</p>
                        <p>Mit meinen zwei Töchtern und meinem Ehemann lebe ich in Bornheim.</p>
                        <p>Ich freue mich auf den neuen Abschnitt in der Niederlassung und möchte Sie in allen Lebensabschnitten begleiten und unterstützen, unabhängig von Alter, Versicherung, Sozialstatus, Herkunft, sexueller Orientierung etc.</p>
                        <p>Für die Frauengesundheit ist eine ganzheitliche Betrachtung von Bedeutung. Daher möchte ich, dass Sie sich über die kompetente fachärztliche Versorgung hinaus rundum wohlfühlen. Mein höchstes Ziel ist Ihre Zufriedenheit.</p>
                        <p>Ich freue mich Sie kennenzulernen!</p>

                        <span>Beruflicher Werdegang im Überblick:</span>
                        <div class="row borders">
                            <div class="col-lg-12 col-md-12 pl-0">
                                <div class="right-title">
                                    <ul>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Studium der Humanmedizin an der Heinrich-Heine- Universität Düsseldorf
                                        </li>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Ausbildung zur Fachärztin für Frauenheilkunde und Geburtshilfe im Kreiskrankenhaus Mechernich und im Krankenhaus der Augustinerinnen in Köln
                                        </li>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Oberärztin im Kreiskrankenhaus Mechernich
                                        </li>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            2023 Übernahme der Praxis für Gynäkologie und Geburtshilfe von Dr. Fischer in Hennef
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <span>Zusatzqualifikationen::</span>
                        <div class="row borders">
                            <div class="col-lg-12 col-md-12 pl-0">
                                <div class="right-title">
                                    <ul>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Psychosomatische Grundversorgung
                                        </li>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Mammasonographie
                                        </li>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Ultraschall-Feindiagnostik ( Organ- Sonographie, fetomaternale Dopplersonographie)
                                        </li>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Qualifikation zur fachgebundenen genetischen Beratung
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


            </div>
        </div>
    </div>
</section>
<!-- End Doctor Details -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
