<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Leistungsspektrum</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">Leistungsspektrum</a></li>
                <li class="active">Gynäkologie & Geburtshilfe</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Portfolio Details Area -->
<div class="portfolio-details-area ptb-100">
    <div class="container">
        <div class="portfolio-details-content">
            <!-- <div class="portfolio-details-img">
                <img src="assets/img/portfolio-details/portfolio-details.jpg" alt="Image">
            </div> -->

            <div class="portfolio-top-content">
                <div class="news-content">
                    <h3>Leistungsspektrum</h3>

                    <p>Wir bieten Ihnen ein breites Spektrum in der Frauenheilkunde und der Geburtshilfe an. Die Aufgabe einer Frauenärztin ist es die Patientinnen bestmöglich in allen Lebensabschnitten, begonnen im Jugendalter über die Schwangerschaftsbetreuung bis hin zur Menopause und darüber hinaus, zu beraten und zu begleiten.
                        Dies ist unsere höchste Priorität.</p>
                    <p>Für Fragen stehen wir Ihnen natürlich zur Verfügung.</p>
                </div>

                <div class="news-content-2">
                    <h3>Gynäkologie</h3>
                    <ul>
                        <li>
                            <i class="bx bx-minus"></i>
                            Diagnostik und Therapie von gutartigen und bösartigen gynäkologischen Erkrankungen bzw. Beschwerden
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Krebsvorsorge
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Erweiterte Vorsorgeuntersuchungen, wie Vaginalultraschall, Brustultraschall und vieles mehr
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Impfungen
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Tumornachsorge
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Teenagersprechstunde
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Hormon - und Wechseljahresdiagnostik
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Diagnostik und Therapie von Inkontinenz- und Senkungsbeschwerden
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Infektionsdiagnostik
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Beratung zur Verhütung sowie Einlage von Spiralen
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Psychosomatische Versorgung
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Kinderwunschberatung und Sterilitätsbehandlung
                        </li>

                    </ul>
                </div>

                <div class="news-content-2">
                    <h3>Geburtshilfe</h3>

                    <ul>
                        <li>
                            <i class="bx bx-minus"></i>
                            Schwangerschaftsvorsorge nach den Mutterschaftsrichtlinien
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Betreuung von Risikoschwangerschaften
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Toxoplasmose- Screening
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Präeklampsie-Screening
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Erweiterte Schwangerschaftsvorsorge
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            3D/4D- Ultraschall
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Fetomaternale Dopplersonographie
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Streptokokken-Test
                        </li>
                        <li>
                            <i class="bx bx-minus"></i>
                            Wochenbettbetreuung
                        </li>

                    </ul>
                </div>

                <div class="news-content-3">
                    <h3>Leistungsspektrum</h3>

                    <p>Einige sinnvolle Untersuchungen werden nicht oder nicht mehr von den gesetzlichen Krankenkassen übernommen, diese bieten wir nach  ausführlicher Beratung und zu Ihrer Sicherheit als individuelle Gesundheitsleistung an.</p>
                    <p>Bei Fragen wenden Sie sich gern an uns!</p>
                    <p></p>
                </div>


            </div>
        </div>
    </div>
</div>
<!-- End Portfolio Details Area -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
