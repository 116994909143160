<!-- Footer -->
<section class="footer-area footer-area-two pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/"><img src="assets/img/logo_hell.png" alt="Image"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">

                    <div class="single-footer-widget">
                        <h3>Kontakt</h3>
                        <ul>
                            <li>
                                <span>Telefon: </span><br/>
                                <a href="tel:+49224283025">+49 (02242) 830-25</a>
                            </li>
                            <li>
                                <span>Email: </span><br/>
                                <a href="mailto:info@frauenärztin-hennef.de">info@frauenärztin-hennef.de</a>
                            </li>
                        </ul>
                    </div>

                    <div class="single-footer-widget">
                        <h3>Adresse</h3>
                        <ul>
                            <li>
                                <p> Frankfurter Straße 100a<br/>
                                    53773 Hennef</p>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Links</h3>

                    <ul>
                        <li><a routerLink="/">Öffnungszeiten</a></li>
                        <li><a routerLink="/">Kontakt</a></li>
                        <li><a routerLink="/datenschutz">Datenschutzerklärung</a></li>
                        <li><a routerLink="/impressum">Impressum</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Öffnungszeiten</h3>
                    <ul class="open-day">
                        <li>
                            Montag
                            <span class="two">8:00 - 12:00</span>
                        </li>
                        <li>
                            &nbsp;
                            <span class="one">13:30 - 16:30</span>
                        </li>
                        <li>
                            Dienstag
                            <span>8:00 - 12:00</span>
                        </li>
                        <li>
                            &nbsp;
                            <span>13:30 - 16:30</span>
                        </li>
                        <li>
                            Mittwoch
                            <span>8:00 - 14:00</span>
                        </li>
                        <li>
                            Donnerstag
                            <span>10:00 - 14:00</span>
                        </li>
                        <li>
                            &nbsp;
                            <span>15:00 - 18:30</span>
                        </li>
                        <li>
                            Freitag
                            <span>8:00 - 12:00</span>
                        </li>
                        <li>
                            und nach Vereinbarung
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Footer -->

<!-- Copyright -->
<div class="copy-right-area copy-right-area-two" style="opacity: 0.1;">
    <div class="container">
        <p>© 2023. Alle Rechte vorbehalten. - Vuci is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
    </div>
</div>
<!-- End Copyright -->

<!-- Go Top -->
<div class="go-top">
    <i class="bx bx-chevrons-up"></i>
    <i class="bx bx-chevrons-up"></i>
</div>
<!-- End Go Top -->
